<template>
	<b-modal
		ref="add-market-group-modal"
		hide-footer
		:title="modalTitle"
		ok-variant="success"
		:ok-title="modalTitle"
		cancel-title="ยกเลิก"
		@show="resetModal"
    @hidden="resetModal"
	>
    <form
      id="market-group-form"
    	ref="form"
    	@submit.stop.prevent="handleSubmit"
    >
    	<b-form-group
        label="กลุ่มหวย"
        label-for="input-market-title"
        :invalid-feedback="validate.groupTitle.text"
      >
        <b-form-input
          id="input-market-title"
          v-model="input.groupTitle"
          type="text"
          placeholder="ชื่อกลุ่มหวย"
          required
          :state="validate.groupTitle.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เรียงลำดับ"
        label-for="input-market-sort"
        :invalid-feedback="validate.sort.text"
      >
        <b-form-input
          id="input-market-sort"
          v-model="input.sort"
          type="text"
          placeholder="เรียงลำดับ"
          required
          :state="validate.sort.status"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
      	<b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      	<b-button type="submit" variant="primary" size="sm" :disabled="isProcess || !input.groupTitle">{{isProcess ? 'กำลังบันทึก...' : modalTitle}}</b-button>
      </div>
  	</form>
  </b-modal>
</template>
<script>
import MarketService from '@/services/marketService'
import { groupInterface } from '@/interfaces/Market'
import Swal from 'sweetalert2'

const validations = {
  groupTitle: {
    status: null,
    text: ''
  },
  sort: {
    status: null,
    text: ''
  }
}

export default {
	name: 'MarketGroupModal',
	props: {
		isShow: {
			type: Boolean,
			default: false
		},
    data: {
      type: Object,
      default: groupInterface
    }
	},
	data() {
		return {
      input: groupInterface,
			isProcess: false,
			needReload: false,
      validate: JSON.parse(JSON.stringify(validations))
		}
	},
  computed: {
    modalTitle() {
      return this.input._id ? 'แก้ไขกลุ่มหวย' : 'เพิ่มกลุ่มหวย'
    }
  },
	watch: {
		isShow() {
			if(this.isShow){
				this.showModal()
			}else{
				this.hideModal()
			}
		},
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
	},
	methods: {
		resetModal() {
			this.needReload = false
      this.validate = JSON.parse(JSON.stringify(validations))
		},
		showModal() {
      this.$refs['add-market-group-modal'].show()
    },
    hideModal() {
      this.$refs['add-market-group-modal'].hide()
    },
		handleSubmit() {
      this.validate = JSON.parse(JSON.stringify(validations))
      this.input.sort = parseInt(this.input.sort)

			if(this.input._id) {
        this.update()
      }else{
				this.save()
			}
		},
    save() {
      if(this.input.groupTitle) {
        MarketService.saveGroup(this.input)
        .then((response)=>{

          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
          }else{
            throw new Error(response?.message)
          }
        })
        .catch((e)=>{

          e?.errors.forEach((item)=>{
            this.validate[item.source.parameter] = {
              status: false,
              text: item?.detail
            }
          })

          Swal.fire({
            title: 'ผิดพลาด!',
            text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    },
    update() {
      if(this.input.groupTitle) {
        MarketService.updateGroup(this.input._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw {
              massage: response
            }
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    }
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
	}
}
</script>
<style lang="scss" scoped>

</style>
