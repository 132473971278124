export const groupInterface = {
  // _id: null,
  groupTitle: '',
  status: 'Active',
  sort: 0
}

export const marketTitleInterface = {
  // _id: null,
  marketTitle: '',
  sort: 0
}

export const marketOpenBetsInterface = {
  threeNumberTop: false,
  threeNumberTode: false,
  twoNumberTop: false,
  twoNumberBottom: false,
  runTop: false,
  runBottom: false
}

export const openBetsInterface = {
  threeNumberTop: {
    isAvailable: false,
    pay: 0,
    discount: 0
  },
  threeNumberTode: {
    isAvailable: false,
    pay: 0,
    discount: 0
  },
  twoNumberTop: {
    isAvailable: false,
    pay: 0,
    discount: 0
  },
  twoNumberBottom: {
    isAvailable: false,
    pay: 0,
    discount: 0
  },
  runTop: {
    isAvailable: false,
    pay: 0,
    discount: 0
  },
  runBottom: {
    isAvailable: false,
    pay: 0,
    discount: 0
  }
}

export const payRateInterface = {
  // _id: null,
  rateTitle: '',
  sort: 0,
  status: 'Active',
  openBets: openBetsInterface
}
