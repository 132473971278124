import { render, staticRenderFns } from "./MarketGroupModal.vue?vue&type=template&id=de9118b0&scoped=true&"
import script from "./MarketGroupModal.vue?vue&type=script&lang=js&"
export * from "./MarketGroupModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de9118b0",
  null
  
)

export default component.exports