<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-1">
  		<strong>กลุ่มหวย</strong>
  		<button class="btn btn-sm btn-link add-group-btn" @click="addGroup">เพิ่มกลุ่ม</button>
  	</div>
		<div class="card">
		  <table class="table table-group table-sm table-hover mb-0">
		  	<thead>
		  		<tr>
            <th class="pl-3" width="20%">สถานะ</th>
		  			<th width="50%">ชื่อกลุ่ม</th>
            <th colspan="2">ลำดับ</th>
		  		</tr>
		  	</thead>
		  	<tbody>
		  		<tr v-for="group in groupList">
            <td class="pl-3"><MarketGroupStatus :status="group.status" /></td>
		  			<td>{{ group.groupTitle }}</td>
            <td>{{ group.sort }}</td>
		  			<td class="text-right">
		  				<MarketGroupButtons :data="group" @edit="editGroup" @delete="deleteGroup" @rollback="rollback" @permanentDelete="permanentDelete" />
		  			</td>
		  		</tr>
          <tr v-if="groupList.length == 0">
            <td colspan="3" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
		  	</tbody>
		  </table>
		</div>

		<MarketGroupModal :data="data" :is-show="isShowGroupModal" @close="modalClosed" />
	</div>
</template>
<script>
import MarketService from '@/services/marketService'
import {groupInterface} from '@/interfaces/Market'

import MarketGroupModal from './components/MarketGroupModal'
import MarketGroupStatus from './components/MarketGroupStatus'
import MarketGroupButtons from './components/MarketGroupButtons'

import Swal from 'sweetalert2'

export default {
	components: {
		MarketGroupModal,
    MarketGroupStatus,
    MarketGroupButtons
	},
	data() {
		return {
			isShowGroupModal: false,
      groupList: [],
      data: groupInterface
		}
	},
	methods: {
    loadGroups() {
      MarketService.getGroups().then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.groupList = response.data
        }
      })
    },
    addGroup() {
      this.data = JSON.parse(JSON.stringify(groupInterface))
      this.isShowGroupModal = true
    },
    editGroup(group) {
      this.data = {
        ...groupInterface,
        ...group
      }
      this.isShowGroupModal = true
    },
    deleteGroup(_id) {
      console.log(_id)
      Swal.fire({
        title: 'ยืนยันการลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.deleteGroup(_id)
          .then((response)=>{
            if(response.success) {
              this.loadGroups()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    rollback(_id) {
      MarketService.rollbackGroup(_id)
      .then((response)=>{
        if(response.success) {
          this.loadGroups()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เรียกคืนกลุ่มเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'เรียกคืนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    permanentDelete(_id) {
      console.log(_id)
      Swal.fire({
        title: 'ต้องการลบถาวร?',
        text: 'ลบถาวรจะไม่สามารถเรียกคืนได้อีก',
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.permanentDeleteGroup(_id)
          .then((response)=>{
            if(response.success) {
              this.loadGroups()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบออกจากฐานข้อมูลเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
		modalClosed(needReload) {
			this.isShowGroupModal = false
			console.log('needReload', needReload)
      if(needReload) {
        this.loadGroups()
        this.$store.dispatch('reloadMarketGroups')
      }
		}
	},
  mounted() {
    this.loadGroups()
  }
}
</script>
<style lang="scss">
.container {
	table.table-group {
		thead {
			tr {
				th {
					font-size: 90%;
					font-weight: normal;
				}
			}
		}
		tbody {
			tr {
				td {
					vertical-align: middle;
					height: 40px;
				}
				// button {
				// 	display: none;
				// }
			}
			// tr:hover {
			// 	button {
			// 		display: inline-table;
			// 	}
			// }
		}
	}
}
</style>
